.dashboard {
  padding: 40px;
  background-color: #f8f9fa;
  font-family: 'Roboto', sans-serif;
}

.dashboard-title {
  font-size: 28px;
  margin-bottom: 30px;
  color: #333;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

.card-icon {
  font-size: 24px;
  margin-bottom: 10px;
}

.card-content h3 {
  font-size: 16px;
  color: #666;
  margin-bottom: 5px;
}

.card-value {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-secondary {
  background-color: #5bc0de;
  color: white;
}

.btn-secondary:hover {
  background-color: #46b8da;
}

.search-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 300px;
}

.lead-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.lead-table th,
.lead-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.lead-table th {
  background-color: #f1f3f5;
  font-weight: bold;
  color: #333;
}

.lead-table tr:hover {
  background-color: #f8f9fa;
}

.tab-menu {
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
  color: #666;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-in-out;
}

.tab-button.active {
  color: #007bff;
  border-bottom-color: #007bff;
}

.success-message, .error-message {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: bold;
}

.success-message {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.error-message {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}
