.stats-container {
  padding: 20px;
  background-color: #f5f7fa;
}

.stats-container h1 {
  color: #333;
  margin-bottom: 30px;
  text-align: center;
}

.stat-section {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 30px;
  padding: 20px;
}

.stat-section h2 {
  color: #2c3e50;
  margin-bottom: 20px;
  border-bottom: 2px solid #ecf0f1;
  padding-bottom: 10px;
}

.stat-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.stat-card {
  position: relative;
  transition: all 0.3s ease;
}

.stat-card.hoverable {
  cursor: pointer;
}

.stat-card.hoverable:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.stat-icon {
  font-size: 2em;
  margin-bottom: 10px;
}

.stat-content h3 {
  margin: 0;
  font-size: 1em;
  color: #666;
}

.stat-value {
  font-size: 1.5em;
  font-weight: bold;
  margin: 5px 0;
}

.click-info {
  display: none;
  font-size: 0.8em;
  color: #666;
  margin-top: 5px;
}

.stat-card.hoverable:hover .click-info {
  display: block;
}

.categories-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  z-index: 10;
}

.categories-dropdown h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.categories-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.categories-dropdown li {
  margin-bottom: 3px;
}

.additional-info {
  font-size: 0.8em;
  color: #666;
  font-weight: normal;
}
